"use client";
import Link from "next/link";
import { Button } from "@heroui/button";
import { useRouter } from "next/navigation";

export default function NotFound() {
  const router = useRouter();

  const handleSignInClick = () => {
    // Store the current path in sessionStorage for redirection after signing in
    sessionStorage.setItem("redirectPath", router.asPath);
    router.push("/sign-in");
  };

  return (
    <div className="container min-h-screen text-center mt-20">
      <h1 className="text-xl mb-4">Page Not Found</h1>
      <h1 className="text-xl mb-4">404 - Error</h1>
      <p>Could not find the requested resource.</p>
      <p className="mt-6 ">
        If you are a subscriber and cannot see the content, please try signing
        out and signing in again.
      </p>
      <div className="mt-10 space-x-4">
        <Button className="bg-1B51CA text-white">
          <Link href="/">Return Home</Link>
        </Button>
        <Button
          className="bg-E6E6E6 text-black hover:bg-gray-300"
          onClick={handleSignInClick}
        >
          Sign In
        </Button>
      </div>
    </div>
  );
}
